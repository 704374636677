import React,{useState,useContext, useEffect} from 'react'
import io from 'socket.io-client'
const SocketContext = React.createContext();

export const useSocket = () => {
    return useContext(SocketContext);
}


/* for n2 server testing work start*/
/* these need to be updated in database notification url */
const n2PortalIds = [
    "global-demo1","global-live", 
    "epf-tbtam",
    "epf-ldjal","epf-rjjod",
    "epf-knmys","epf-mragr", "epf-pupuna",
    "epf-dlw","epf-gnggn1","epf-tbvlr" ,"epf-tnamb","epf-bgbngm","epf-pybomk","epf-bgbngc"
]
/* for n2 server testing work end s*/


const n4PortalIds = [
    "epf-neghy", "epf-pupunc",
]
/* for n2 server testing work end s*/

export const SocketProvider = (props) =>{

    const [appName,setAppName] = useState(null);
    const [connectionUrl,setConnectionUrl] =  useState(null)

    

    const getAppName = () =>{
        let _appName = "k-"+localStorage.getItem("portalId");
        /* for n2 server testing work start*/
        /* this section wil not required after updated in database and one time run */
        if(n2PortalIds.includes(localStorage.getItem("portalId"))){
            console.log("need to update notification url")
            localStorage.setItem("notificationUrl","https://n2.gtoken.in/")
        }
         /* for n2 server testing work end */
         if(n4PortalIds.includes(localStorage.getItem("portalId"))){
            localStorage.setItem("notificationUrl","https://n4.gtoken.in/")
        }
        let notificationUrl = localStorage.getItem("notificationUrl");
        setAppName(_appName);
        let _connectionUrl = notificationUrl+"?app="+_appName;
        setConnectionUrl(_connectionUrl)
        //let _socket = io.connect("http://206.189.138.129:3001?app="+_appName,{
        /*let _socket = io.connect(notificationUrl+"?app="+_appName,{
            //   path: '/socket.io', transports: ['websocket'] 
            //transports: ['websocket'],
            //pingInterval: 1000 * 60 * 5,
            //pingTimeout: 1000 * 60 * 3
        });
        */
        
    }

    useEffect(()=>{
        getAppName()
    },[]);
    
    const value= {
        appName,
        connectionUrl,
    }

    return (
        <SocketContext.Provider value= {value}>
            {props.children}
        </SocketContext.Provider>
    )
}